<template>

  <div class="video_item">
    <NuxtLink data-gallery="" data-type="photo" class="img_gallery" :to="data.size_big" @click.stop.prevent="clickPhoto">     
      
      <div v-if="data.video_id" class="video_play"></div>
      <div v-if="data.video_id" class="media_duration">{{ sec(data.duration) }}</div>

      <img :src="data.size_m" alt="">
    </NuxtLink>


    <div class="video_info">
      <NuxtLink :to="data.item_diary.link" class="ttl">
       {{ $t('strain_view_latest_photos_videos_week_sing') }}
       {{ data.item_week.days }}
      </NuxtLink>
      <NuxtLink v-if="data.items_seed" :to="data.items_seed[0].link" class="hint">
        {{ data.items_seed[0].name }}
      </NuxtLink>
    </div>

  </div>

</template>


<script setup>


const emits = defineEmits(['clickPhoto'])

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})
 
const clickPhoto = () => {
  emits('clickPhoto')
}
 
const sec = function(d){      
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + ":" : "";
  var mDisplay = m + ":";
  var sDisplay = s + "";
  return hDisplay + (mDisplay < 10 ? "0" + mDisplay : mDisplay) + (sDisplay < 10 ? "0" + sDisplay : sDisplay); 
}
</script>
   

<style scoped>
  
  
.gallery{
    width: 100%;
}

.photos_gallery{
  display: block;
  overflow-y: hidden;
  text-align: left;
  width: 100%;
  height: 100px;
     white-space: nowrap;
     position: relative;
}
.photos_gallery::before{
  content: ' ';
   box-shadow: white -150px 0em 70px -90px inset;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.photos_gallery a{

}
.photos_gallery > a > img{
    width: 100px;
    height: 100px;
    border-radius: 3px;
}

.videos_gallery{
    display: block;
    text-align: left;
    margin-top: 20px;
}
.video_item{
    display: inline-block;
    width: 19.4%;
    margin-left: 0px;
    margin-right: 0;
    vertical-align: top;
    position: relative;
    margin-bottom: 17px;
}


.gallery{
    overflow-x: hidden;
       white-space: nowrap;
       position: relative;
}
.gallery::before{
  content: ' ';
   box-shadow: white -150px 0em 70px -90px inset;
  position: absolute;
  width: 100%;
  height: 100%;
     z-index: 2;
     pointer-events: none;
}



.video_item .video_info{

}

.video_item .video_info .ttl{
    display: block;
    text-transform: capitalize;
     font-weight: bold;
}
.video_item .video_info .hint{
    color: gray;
    display: block;
   font-size: 0.85rem;
}

.video_item > .img_gallery{
    display: inline-block;
    width: 100%;
    height: auto;
    overflow-y: hidden;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}
.video_item > .img_gallery img{
  border-radius: 5px;
  aspect-ratio: 20/20;
    width: 100%;
}
.video_item > .img_gallery .media_duration{
  background: rgb(0, 0, 0);
    /* margin-bottom: 10px; */
    padding: 4px;
    /* max-width: 720px; */
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.53); */
    border-radius: 4px;
    width: 58px;
    text-align: left;
    margin-bottom: 0px;
    position: absolute;
    /* max-height: 100%; */
    overflow: hidden;
    line-height: 16px;
    z-index: 1;
    max-width: 70%;
    color: white;
    font-size: 0.85rem;
    opacity: 0.8;
    text-align: center;
    right: 6px;
    bottom: 11px;
}
.videos_gallery > .video_item > .img_gallery > img{
    width: 100%;
    height: auto;
    margin-top: -10px;
}
.video_play {
    width: 56px;
    height: 56px;
    z-index: 1;
    position: absolute;
    background-image: url(/images/play_button.png);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 62px;
    left: calc(50% - 28px);
    top: calc(50% - 28px);
    cursor: pointer;
    pointer-events: none;
}

@container pb (max-width: 750px) {

  .video_item {
    width: calc(100% / 4 - 5px);
  }

}

@container pb (max-width: 600px) {

  .video_item {
    width: calc(100% / 3 - 5px);
  }

}

@container pb (max-width: 500px) {

  .video_item {
    width: calc(100% / 2 - 5px);
  }

}


</style>
