<template>
  <div class="pbf grid_width">
  
    <GeneralSearchTags 
      :tags="props.intags" 
      :tagsselected="tagsselected" 
      :tagsdefault="props.intags" 
      :tagscount="gallery_data?.items_tags"    
      @choose="chooseTags"
      @unchoose="unchooseTags"
      />

    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :align="'right'"
        :options="[
          {id:'week_update', name: $t('universal_sort_last_update')},            
        ]"
        />
    </div>

    
    <SkPhotos 
        v-if="is_loading && start == 0"
        />
      
    <div v-else class="gallery_rows" id="gallery">
      <GeneralPhoto 
        v-for="(opt,ind) in gallery_data?.items"
        :key="ind"  
        :data="opt"    
        @clickPhoto="openGallery(ind)"  
        >       
      </GeneralPhoto>    
      
    </div>

    <client-only>        
      <InfinityScroll 
        v-if="gallery_data?.items?.length >= limit"
        :is-loading="is_loading"
        ref="el_infinity_scroll"/>
    </client-only>


    <GeneralLightbox 
      v-if="lightboxShow"
      :index="lightboxIndex" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
      />
  

  </div>
</template>

<script setup>


import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';

const is_ended = ref(false)
const {$api, $adv, $tagsUtil, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)

const props = defineProps({
  inurl: {
    type: String,
    required: true
  },
  intags: {
    type: Object,
    required: true
  }
})
const lightboxShow = ref(false);
const lightboxIndex = ref(0);

const start = ref(0);
const limit = ref(20);
const query = ref('');
const sort = ref('week_update');
const tagscount = ref([]);
const tagsselected = ref([]);
const tagspermanent = ref([]);
const tagsdefault = ref(null);
const list = ref([]);


const lightboxPhotos = computed(() => {
  var items = [];
  for(var i of gallery_data.value?.items){
    let photo = {}; 
    if(i.is_video){
      photo.type = 'iframe';
      photo.iframe = '/video/' + i.video_id;
    }          
    photo.base = i.size_big;
    photo.zoom = i.size_big; 
    items.push(photo);        
  }
  return items;
})
 
const openGallery = function(key){
  console.log('open');
  lightboxShow.value = true;
  lightboxIndex.value = key;
}
 
const clearPagination = function(){     
  start.value = 0;  
}


const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){          
  tagsselected.value.push(selected);      
  clearPagination();    
  $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(props.intags, tagsselected.value, tagspermanent.value));
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}
 

// const openGallery = function(key){ 
   
//   var lightbox = new PhotoSwipe({
//     gallery: '#gallery',
//     children: 'a',
//     dataSource: list.value,
//     index: key,
//     showHideAnimationType: 'none',                     
//   })
    
//   lightbox.init();
// }




// const infiniteHandler = function(state){
//   start.value += limit.value;
//   loadResults(state);
// }

// const loadResults = async function(state){

//   var dt = await $api.getUrl(inurl, {        
//     start: start.value,
//     limit: limit.value,
//     q: query.value,
//     sortable: sort.value,
//     tags: tagsselected.value.join(',')                       
//   })
        
  
//   if(state && !dt.items.length) state.complete();
//   if(state && dt.items.length) state.loaded();    
  
  
//   tagscount.value = dt.items_tags;    
//   list.value.push(...dt.items);

// }


const loadData = async function() {  
  const response = await $api.getUrl(props.inurl, {
    start: start.value,
    limit: limit.value,
    q: $tagsUtil.getTagsKeyword(props.intags, tagsselected.value),   
    week: $tagsUtil.getTagsWeek(props.intags, tagsselected.value), 
    sortable: sort.value,
    tagsall: $tagsUtil.getTagsKeys(props.intags),
    tags: $tagsUtil.getTagsSimple(props.intags, tagsselected.value)
  });
  return response;  
}



const { pending: is_loading,  data: gallery_data } = await useLazyAsyncData('gallery_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    gallery_data.value.items = [];
    is_ended.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    is_ended.value = true;  

  gallery_data.value.items = [...gallery_data.value.items, ...dt.items]
  gallery_data.value.items_tags = dt.items_tags
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})



useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)



</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}

.gallery_rows{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.sortb{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

</style>
